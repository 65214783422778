<template>
  <div class="">
    <ActivityForm
      v-if="activityType.id === 1"
      ref="activitiesForm"
      :form-title="`Create a new ${activityType.name}`"
      :type-id="activityType.id"
      :type-name="activityType.name"
      @formChanged="formChanged = $event"
    />
    <CampForm
      v-if="activityType.id === 2"
      ref="activitiesForm"
      :form-title="`Create a new ${activityType.name}`"
      :type-id="activityType.id"
      :type-name="activityType.name"
      @formChanged="formChanged = $event"
    />
    <EventForm
      v-if="activityType.id === 3"
      ref="activitiesForm"
      :form-title="`Create a new ${activityType.name}`"
      :type-id="activityType.id"
      :type-name="activityType.name"
      @formChanged="formChanged = $event"
    />
  </div>
</template>

<script>
import ActivityForm from '@/components/ActivityForm'
import CampForm from '@/components/CampForm'
import EventForm from '@/components/EventForm'
import activityType from './activityType'

export default {
  name: 'Create',
  components: {
    ActivityForm,
    CampForm,
    EventForm,
  },
  data() {
    return {
      activityType: {
        name: '',
        id: +this.$route.fullPath.split('/')[2],
      },
      formChanged: false,
    }
  },
  mounted() {
    switch (this.activityType.id) {
      case activityType.ACTIVITY:
        this.activityType.name = 'Activity'
        break
      case activityType.CAMP:
        this.activityType.name = 'Camp'
        break
      case activityType.EVENT:
        this.activityType.name = 'Event'
        break
    }
    // Remove the focus from options input and scroll to top in create form only
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 80)
  },
  beforeRouteLeave(to, from, next) {
    if (this.formChanged) {
      this.$refs.activitiesForm.show = true
      this.$refs.activitiesForm.modalOptions = {
        clickedId: -1,

        text: this.$t(`Do you want to save this as a draft?`, {
          activityType: this.$t(this.activityType.name),
        }),
        okButton: this.$t('Yes, save draft'),
        cancelButton: this.$t('No, discard'),
        goToPath: to.name,
      }
      next(false)
    } else {
      next()
    }
  },
}
</script>

<style scoped></style>
